import './App.css';
import React from "react";
import { Typography,Grid, TextField } from '@material-ui/core';
import InputLabel from "@material-ui/core/InputLabel";
import CheckIn from "./Components/CheckIn";
import Animate from "./Components/Animate";



function App() {
  return (
    <div className="App">
       {/*<CheckIn/>*/}
       <Animate/>
    </div>
  );
}

export default App;
