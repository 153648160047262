import React, {useState} from "react";

import { useSpring, animated, config } from '@react-spring/web'
import { useDrag } from 'react-use-gesture'
import Typography from "@material-ui/core/Typography";
import {Grid} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import MuiPhoneNumber from "material-ui-phone-number";
import DayjsUtils from "@date-io/dayjs";
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import validator from 'validator';


import { FirebaseDatabaseProvider } from "@react-firebase/database";


import axios from 'axios';


export default function Animate(props)
{

    const [{ x, y }, api] = useSpring(() => ({ x: 0, y: 0, config: { ...config.gentle }}))

    const [phoneNumber, setPhoneNumber] = useState("");
    const [dob,handleDobChange] = React.useState(new Date());


    const [submitted, setSubmitted] = useState(false);


    const [status,setStatus] = useState("Checking in..");
    const [preStatus,setPreStatus] = useState("Continue swiping");


    const handlePhoneNumber = (value)=>{
        setPhoneNumber(value);
    };


    const bind = useDrag(({ down, movement: [mx, my] }) => {

        api.start({ x: 0, y: my })


        if(my<-200 && !down)
        {
            const urlParams = window.location.search;
            const facilityPhone = new URLSearchParams(urlParams).get("facility")
            console.log(facilityPhone)

            if(!facilityPhone)
            {
                setPreStatus("Invalid URL. Contact your provider.")
                api.start({x: 0, y: 0})
                return;
            }


            //validate
            if(validator.isMobilePhone(phoneNumber,"en-US"))
            {
                setPreStatus("Keep swiping")
                api.start({x:0,y:-900})
                console.log("submitting")
                setSubmitted(true);

                //phoneNumber:phoneNumber,
                //dob:dob.toDateString(),
                //facilityPhone:""

                // console.log(dob.toLocaleString());

                // console.log(dob.toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}));
                axios.post("https://us-central1-hometestbox-aad36.cloudfunctions.net/checkIn",
                     {
                            "phoneNumber":phoneNumber,
                            "dob":new Date(dob).toLocaleDateString('en-US', {year: 'numeric', month: '2-digit', day: '2-digit'}),
                            "facilityPhone":new URLSearchParams(urlParams).get("facility")
                        },
                    ).then((res)=>{
                    setStatus(res.data.message);
                }).catch((err)=>{
                    setStatus(err);
                    console.log(err)
                    // api.start({x:0,y:0})
                    // setSubmitted(true);
                })
            }
            else {
                setPreStatus("Invalid phone number. Try again.")
                api.start({x: 0, y: 0})

            }





        }
        else
        {
            if(!down) {
                api.start({x: 0, y: 0})

            }

        }


    },{useTouch:true})


    // const bind = useDrag(({ down, movement: [y], args: [index] }) => api.start(i => i === index && { y: down ? y : 0 }), {
    //     axis: 'y',
    // })



    return (
        <div style={{height:"-webkit-fill-available"}}>
        <animated.div  {...bind()}  style={{ x,y,backgroundColor:"#fefffd", touchAction: "pan-up"}} className="fillScreen" >

            <MuiPickersUtilsProvider utils={DayjsUtils}>
            <Grid container justify="center" alignContent="center" >

                <Grid item sm={12} xs={12} style={{marginTop:"100px"}}>
                      <Typography gutterBottom variant="h1" style={{fontSize:"3rem"}}>
                         Check In
                      </Typography>
                </Grid>

                <Grid item lg={9} sm={8} xs={8}>
                    <InputLabel>Phone Number</InputLabel>
                    <MuiPhoneNumber style={{width:"100%"}} countryCodeEditable={false} defaultCountry={'us'} onlyCountries={['us']}  disableAreaCodes  onChange={handlePhoneNumber}/>
                </Grid>


                <Grid item lg={9} sm={6} xs={6} style={{marginTop:"50px"}}>
                    <KeyboardDatePicker
                        style={{width:"100%"}}
                        disableFuture
                        autoOk
                        clearable
                        value={dob}
                        onChange={date=>handleDobChange(date)}
                        label="Date of birth"
                        maxDateMessage={"Please select a valid date for the Date of Birth."}
                        placeholder="12/1/2020"
                        format="MM/DD/YYYY"/>

                </Grid>





            </Grid>
            </MuiPickersUtilsProvider>


            <ExpandLessIcon style={{position:"relative",top:"190px"}}/>
            <Typography style={{position:"relative",top:"100px"}}>
                SWIPE UP TO SUBMIT
            </Typography>
            <FingerprintIcon  style={{fontSize:50, position:"relative",top:"210px",bottom:"80px"}} />




        </animated.div>
            <div hidden={submitted} style={{zIndex:"-3",position:"absolute",bottom:"10px",fontSize:"1rem",left:"0",right:"0"}}>
                <Typography variant="h1" style={{fontSize:"1rem"}}>
                    {preStatus}
                </Typography>
            </div>
            <div hidden={!submitted} style={{zIndex:"-3",position:"absolute",bottom:"10px",fontSize:"1rem",left:"0",right:"0",backgroundColor:"#282828",padding:"250px 0 250px 0",height:"-webkit-fill-available"}}>
                    <img style={{width:"240px"}} src={"https://viveraad.com/labportpng"}/>

                <Typography variant="h1" style={{fontSize:"1rem",color:"#fff"}}>
                    {status}
                </Typography>

            </div>

        </div>
    )
}
